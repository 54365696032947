* {
  font-family: "Sora", sans-serif !important;
}
html {
  scroll-behavior: smooth !important;
}
body {
  overflow-x: hidden !important;
}
#btnGetStarted,
#btnSend {
  transition: all 0.2s;
}
